// Import SCSS Variables
@import "variables";

// Home Page - Hero Section  
.bh-home-banner {
  background-image: url("../images/Hero_BANNER.png");
  width: 100%;
  min-height: 700px;
  position: relative;
  display: flex;
  align-items: end;
  background-size: cover;
  background-position: top;
  padding: 80px 0;

  &::after {
    content: "";
    background-image: url("../images/bg-gradient.svg");
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 500px;
    z-index: 1;
  }

  .home-banner-content {
    position: relative;
    z-index: 1024;
    max-width: 664px;

    .bh-title {
      color: #fff;
      font-size: $fs-36;
      font-weight: $semi-bold;
      margin-bottom: 40px;

      .text-grey {
        color: $grey;
      }
    }

    .banner-title {
      font-size: $fs-80;
      font-weight: $extra-bold;
      color: #fff;
      margin-bottom: 0;

      .text-orange {
        color: $orange;
      }
    }

    .say-hi {
      font-size: $fs-20;
      margin-bottom: 0;
      font-weight: $semi-bold;

      &.text-grey {
        color: $light-text;
      }
    }
  }

  .event-card {
    position: relative;
    z-index: 1024;

    .event-dt {
      display: block;

      .next-event-title {
        display: flex;
        align-items: center;
        position: relative;
        font-size: $fs-16;
        font-weight: $semi-bold;
        color: $light-text;
        padding-left: 20px;
        margin-bottom: 10px;

        &:before {
          content: "";
          background-color: $orange;
          width: 11px;
          height: 11px;
          border-radius: 100%;
          position: absolute;
          left: 0;
        }
      }

      .event-image {
        overflow: hidden;
        border-radius: 6px;
        width: 330px;
        height: 200px;
        position: relative;

        img,
        video {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          object-fit: cover;
        }

        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

    }
  }
}

// Home Page - Hero Section Responsive 
.exce-home-page-heroSection {

  .bh-title {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 30px !important;
      margin-bottom: 30px !important;
    }
  }

  .banner-title {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 40px !important;
    }
  }

  .exce-video-outer {
    @media (min-width:350px) and (max-width:640px) {
      padding-top: 4rem !important;
    }

    @media (min-width:768px) and (max-width:1024px) {
      padding-top: 4rem !important;
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    @media (min-width:1280px) and (max-width:1536px) {
      padding: 0 !important;
    }
  }

  .event-image {
    @media (min-width:768px) and (max-width:1024px) {
      width: 100% !important;
      height: 500px !important;
    }

    @media (min-width:1280px) and (max-width:1536px) {
      width: 100% !important;
      // height: 500px !important;
    }
  }

  .exce-heroSection-main-div {
    @media (min-width:768px) and (max-width:1024px) {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }

  .exce-heroSection-main-col {
    @media (min-width:768px) and (max-width:1024px) {
      width: 100%;
    }

    @media (min-width:1280px) and (max-width:1536px) {
      width: 70%;
    }

  }
}

// Home Page - About Section
.bh-about-sec {
  .about-title {
    margin-bottom: 45px;

    .title-heading {
      text-align: left;

      .bh-tag-heading {
        display: inline-block;
        text-transform: uppercase;
        color: $white;
        font-weight: $semi-bold;
        font-size: $fs-20;
        width: 418px;

        small {
          display: block;
          color: $text-light-w;
          font-size: $fs-16;
          font-weight: $medium;
          text-transform: capitalize;
        }
      }

      i {
        width: 34px;
        height: 41px;
        display: inline-block;
        font-size: $fs-46;
        color: $color-light;
        transform: rotate(180deg);
        opacity: 0.4;
        will-change: transform;
      }
    }
  }

  .hm-about-content {
    .rating-view {
      margin-bottom: 35px;

      ul {
        display: flex;
        justify-content: space-between;

        li {
          color: $text-light-w;
          font-size: $fs-20;
          font-weight: $medium;
          max-width: 330px;

          .media-rating {
            position: relative;

            .user-pic {
              max-width: 46px;
              height: 46px;
              overflow: hidden;
              position: absolute;
              left: 0;
            }

            .review-content {
              padding-left: 60px;

              p {
                margin: 5px 0 10px;
                font-size: $fs-16;
                font-weight: $medium;
              }

              .review-value {
                display: block;
                font-size: $fs-12;
                font-weight: $semi-bold;
                color: $white;

                .review-start {
                  margin-left: 5px;
                }
              }

              .review-name {
                display: block;
                font-size: $fs-12;
                font-weight: $semi-bold;
                color: $white;
              }
            }
          }
        }
      }
    }

    .profile-about {
      border-radius: 12px;
      overflow: hidden;
    }
  }

  .bh-ab-photoshoot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .about-moment-card {
      background-color: $white;
      border-radius: 12px;
      overflow: hidden;
      padding: 20px;

      .about-moment-content {
        padding: 15px;

        .moment-title {
          font-size: $fs-24;
          font-weight: $semi-bold;
          margin-bottom: 15px;
        }

        .moment-desc {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin: 0;
            font-size: $fs-16;
            font-weight: $medium;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

// Home Page - About Section Responsive
.exce-home-page-AboutSection {

  overflow: hidden;

  .title-heading {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 30px !important;
    }

    @media (min-width:768px) and (max-width:1536px) {
      font-size: 40px !important;
    }


  }

  .bi-quote {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 40px !important;
    }
  }

  .bh-tag-heading {
    @media (min-width:350px) and (max-width:640px) {
      padding-bottom: 2rem !important;
    }
  }

  .rating-view {

    ul {

      @media (min-width:350px) and (max-width:640px) {
        flex-direction: column;
        gap: 2rem;
      }

    }

  }


  .exce-about-bh-photoshoot-col {
    @media (min-width:768px) and (max-width:1024px) {
      margin: 0 !important;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .bh-ab-photoshoot {

    @media (min-width:350px) and (max-width:1024px) {
      padding-top: 2rem !important;
      gap: 2rem;
      align-items: flex-start;
    }

  }

  .go-link {
    @media (min-width:768px) and (max-width:1024px) {
      padding-left: 1rem;
    }
  }

  .exce-about-row {

    @media (min-width:768px) and (max-width:1024px) {
      display: flex;
      flex-direction: column !important;
      margin: 0 !important;
      width: 100%;
    }

    .exce-about-col {
      @media (min-width:768px) and (max-width:1024px) {
        margin: 0 !important;
        width: 100%;

      }
    }

  }

}

// Home Page - Together Section 
.bh-together {
  .title-sec {
    margin-bottom: 50px;
  }

  .item-hover {
    &:hover {
      .services-box {
        opacity: 0.2;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }

  .services-box {
    transition: opacity .4s;

    img {
      max-width: 100%;
    }
  }
}

// Home Page - Together Section Responsive
.exce-together-section {

  @media (min-width:350px) and (max-width:640px) {
    padding-top: 0 !important;
  }

  .title-heading {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 30px !important;
    }
  }
}

// Home Page - Events Section  
.bh-upcomming-event {
  .event-tab-menu {
    display: inline-flex;
    align-items: center;
    height: 46px;
    background-color: $bg-dark-card;
    border-radius: 100px;

    .tab-button {
      color: $tabDefault;
      background-color: transparent;
      box-shadow: none;
      outline: none;
      border: 1px solid transparent;
      width: 127px;
      height: 45px;
      border-radius: 100px;
      font-size: $fs-16;
      font-weight: $medium;

      &.active {
        background-color: $tabActive;
        color: $tabActiveText;
      }
    }
  }

  .bh-event-card-exce {
    border-radius: 6px;
    overflow: hidden;

    @media (min-width:350px) and (max-width :640px) {
      max-height: 450px;
    }

    @media (min-width:768px) and (max-width :1024px) {
      height: 450px;
    }

    @media (min-width:1280px) and (max-width :1536px) {
      height: 450px !important;
      width: 500px !important;
    }

    .event-body-content {
      background-color: $bg-dark-card;
      padding: 15px;

      .event-title {
        font-size: $fs-26;
        font-weight: $semi-bold;
        color: $white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .event-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .event-price {
          font-size: $fs-26;
          color: $white;
          font-weight: $semi-bold;

          .event-offer {
            font-size: $fs-16;
            color: $colorgrey;
            text-decoration-line: line-through;
          }
        }

        .btn-default {
          height: 45px;
          font-size: $fs-14;
          font-weight: $semi-bold;
          border-radius: 100px;
          padding: 12px 20px;
          min-width: 156px;
        }
      }
    }
  }

  .title-sec {
    margin-bottom: 35px;
  }

  .bh-event-image {
    position: relative;
    max-height: 290px;
    overflow: hidden;

    img {
      max-width: 100%;
      width: 100%;
    }

    .event-dt {
      position: absolute;
      bottom: 20px;
      left: 15px;
      right: 0;

      .event-logo {
        margin-bottom: 35px;
        margin-left: 15px;

        img {
          max-width: 100%;
          width: auto;
        }
      }
    }
  }

  .event-badge {
    font-size: $fs-14;
    font-weight: $medium;
    color: $white;
    background-color: $bgblack-g;
    height: 39px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px;
    margin-bottom: 5px !important;

    .event-icon {
      margin-right: 6px;
    }

    &:not(:last-child) {
      margin-right: 15px !important;
    }
  }

  .tab-slider {
    .swiper-content {
      @media (max-width: 1024px) {
        padding: 0 10px;
      }
    }
  }
}

// Home Page - Blog Section
.bh-blog-sec {
  .blog-card {
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 35px 30px;

    &:hover {
      cursor: pointer;
    }

    &.bg-blog {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      overflow: hidden;
      border-radius: 25px;
    }

    &.blog-1 {
      background-image: url("../images/blog-1.png");
    }

    &.blog-2 {
      background-image: url("../images/blog-2.png");
    }

    .blog-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $white;

      .post-date {
        font-size: $fs-20;
        font-weight: $semi-bold;
      }

      .post-arrow {
        font-size: $fs-20;
      }
    }

    .blog-title {
      font-size: $fs-36;
      font-weight: $medium;
      margin-bottom: 0;
      color: $white;
    }
  }
}

// Home Page - Blog Section Responsive
.exce-home-page-blogs {

  .title-heading {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 40px;
    }
  }

  .blog-title {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 30px !important;
    }
  }

  .title-with-desc {
    @media (min-width:768px) and (max-width:1024px) {
      padding: 0 !important;
    }
  }

  .exce-blog-main-row-inner-col {
    @media (min-width:768px) and (max-width:1024px) {
      width: 100%;
    }
  }

  .exce-blog-col-outer {
    @media (min-width:768px) and (max-width:1024px) {
      width: 100%;
    }
  }

  .exce-blog-main-row {

    @media (min-width:768px) and (max-width:1024px) {
      display: flex;
      flex-direction: column;
      gap: 3rem !important;
    }

  }

  .exce-blog-row-outer {

    @media (min-width:350px) and (max-width:640px) {
      display: flex;
      flex-direction: column;
      gap: 3rem !important;
    }

  }
}

// Home Page - Podcast Section 
.bh-podcast-sec {
  .title-sec {
    margin-bottom: 45px;
  }

  .podcast-play-img {
    max-width: 256px;
    height: 256px;
    border-radius: 12px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .current-play-dt {
    margin-top: 20px;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        position: relative;
        font-size: $fs-12;
        font-weight: $medium;
        color: $textDarkGrey;
        text-transform: uppercase;
        padding-left: 15px;
        padding-right: 15px;

        &:not(:last-child)::after {
          content: "";
          background-color: $textDarkGrey;
          width: 4px;
          height: 4px;
          border-radius: 100px;
          position: absolute;
          right: 0;
          top: calc(50% - 1px);
        }
      }
    }
  }

  .track-play {
    margin-top: 35px;

    .track-title {
      font-size: $fs-46;
      font-weight: $semi-bold;
      text-align: center;
      margin-bottom: 25px;
    }
  }

  .podcast-play-list {
    a {
      color: black;
    }

    .podcast-itme-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 15px;

      .podcast-media {
        display: flex;
        align-items: center;

        .media-block {
          width: 80px;
          height: 80px;
          border-radius: 6px;
          overflow: hidden;
          margin-right: 15px;

          img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .media-title {
          font-size: $fs-20;
          font-weight: $semi-bold;
          margin-bottom: 0;
        }
      }

      .current-play-dt {
        margin-top: 0;
        width: 400px;

        @media (max-width: 1199.98px) {
          width: 300px;
        }

        @media (max-width: 767.98px) {
          width: 300px;
        }

        ul {
          justify-content: start;
          margin-bottom: 10px !important;

          li {
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }

      .podcast-play-btn {
        .listen-btn {
          font-size: $fs-12;
          font-weight: $semi-bold;
          border: none;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 46px;
          padding-left: 15px;
          padding-right: 15px;
          background-color: $white;

          i {
            font-size: $fs-24;
            width: 24px;
            height: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $seprator;
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }
}

// Home Page - Podcast Section Responsive
.exce-home-page-podcast {

  overflow: hidden;

  .title-heading {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 40px;
    }
  }

  .track-title {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 30px !important;
    }
  }

  .audio-player {
    @media (min-width:350px) and (max-width:640px) {
      width: 100%;
    }
  }

  .podcast-play-btn {
    @media (min-width:350px) and (max-width:640px) {
      display: none;
    }
  }
}

//  Slider btn
.slider-tab-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .custom-navigation {
    max-width: 88px;
    margin-bottom: 0;

    .arrow-btn {
      width: 40px;
      height: 40px;
      background-color: $btnColor;
      border-radius: 100px;
      border: none;
      font-size: $fs-14;
      color: $white;
      font-weight: $semi-bold;

      &:hover {
        background-color: $tabActive;
        color: $tabActiveText;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }

      &.swiper-button-disabled {
        opacity: 0.6;
      }
    }
  }
}

// Home Page - Testimonials Section
.bh-testimonials-sec {
  .testimonials-heading {
    max-width: 900px;

    .title-heading {
      text-align: left;
    }
  }

  .slider-tab-toolbar {
    align-items: end;

    .custom-navigation {
      .arrow-btn {
        &:hover {
          background-color: $bgLightColor;
          color: $text-black;
        }
      }
    }
  }

  .tab-slider {
    .swiper-content {
      @media (max-width: 1024.98px) {
        padding: 0 10px;
      }
    }
  }
}

.bt-testimonials-card {
  background-color: $bgLightColor;
  border-radius: 12px;
  padding: 25px;

  .testimonials-content {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    .company-logo {
      display: block;
      max-width: 85px;
      height: 25.66px;
    }

    .testimonials-message {
      font-size: $fs-26;
      font-weight: $semi-bold;
      color: $text-black;
      margin: 15px 0;
    }

    .author-desc {
      .author-name {
        display: block;
        font-size: $fs-16;
        font-weight: $semi-bold;
        color: $text-black;
      }

      .author-designation {
        font-size: $fs-16;
        font-weight: $medium;
        color: $text-grey;
      }
    }
  }

  .testimonials-profile-user {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 400px;
    margin-left: auto;

    .play-video-btn {
      border: none;
      width: 60px;
      height: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      font-size: $fs-26;
      font-weight: $semi-bold;
      position: absolute;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      background-color: $dark-light-color;
      color: $white;
    }

    @media (max-width: 1600.98px) {
      width: auto;
      height: auto;
    }
  }
}

// Home Page - Testimonials Section - Responsive
.exce-home-page-testimonial {

  .tab-slider {
    @media (min-width:350px) and (max-width:640px) {
      position: relative;
      padding-bottom: 4rem;
    }
  }

  .title-heading {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 40px;
    }

    @media (min-width:768px) {
      font-size: 60px;
    }
  }

  .testimonials-message {
    @media (min-width:350px) and (max-width:1536px) {
      padding: 2rem 0rem !important;
      margin: 0 !important;
    }
  }

  .testimonials-profile-user {
    @media (min-width:350px) and (max-width:640px) {
      padding-top: 2rem !important;
    }

    @media (min-width:1280px) and (max-width:1536px) {
      height: 100%;
    }
  }

  .slider-tab-toolbar {
    @media (min-width:350px) and (max-width:640px) {
      justify-content: center;

    }
  }

  .custom-navigation {

    @media (min-width:350px) and (max-width:640px) {
      position: absolute;
      bottom: -1rem;
    }

    @media (min-width:768px) and (max-width:1536px) {
      display: flex;
      margin-bottom: 35px;
    }

  }

}

// MetaAwakening Css
.exce-home-page-animation-aweking {

  height: 3000px;
  width: 100%;
  scrollbar-width: none;
  position: relative;

  @media (min-width:350px) and (max-width:640px) {
    height: 1800px;
  }

  .bh-meta-awakening {
    height: fit-content;
    width: 100%;
    scroll-snap-align: start;
    transition: ease-in-out;
    position: relative;

    .position-sticky-awe {
      height: 100vh;
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: start;
      padding-top: 80px;
      justify-content: center;
      scroll-snap-align: start;
    }

    .meta-awakening-desc {
      max-width: 80%;
      margin-left: 210px;

      @media (min-width: 350px) and (max-width: 640px) {
        margin-left: 10px;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        margin-left: 50px;
        max-width: 80%;
      }

      @media (min-width: 1280px) and (max-width: 1536px) {
        margin-left: 150px;
        max-width: 80%;
      }

      p {

        @media (min-width: 350px) and (max-width: 640px) {
          font-size: 14px;
          max-width: 100%;
        }

        font-size: $fs-20;
        font-weight: $medium;
        color: $white;
      }
    }

    .title-sec {
      margin-top: 150px;
      max-width: 1675px;
      text-wrap: nowrap;
      white-space: nowrap;
      margin-left: auto;
      overflow: hidden;

      @media (max-width: 991.98px) {
        max-width: 100%;
      }

      .title-heading {
        font-size: $fs-180;
        font-weight: $extra-bold;
        color: $white;

        @media (max-width: 991.98px) {
          font-size: $fs-26;
        }
      }
    }

    .component-animation {
      width: 100%;
    }

    .awakening-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80%;
      width: 100%;
      padding-bottom: 50px;

      @media (min-width: 350px) and (max-width: 640px) {
        height: 70%;
        padding-bottom: 0;
      }
    }

    .text-rail-wrapper {
      width: 100%;
      overflow: hidden;
      padding: 10px 0;
      white-space: nowrap;
      position: relative;
    }

    .text-rail-track {
      display: inline-block;
      animation: scroll-left 10s linear infinite;
      white-space: nowrap;
      will-change: transform;
    }

    .text-rail-text {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      padding-left: 100%;
    }

    .awakening-inner {
      position: relative;
      overflow: hidden;

      .bgimage {
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        transition: transform 0.5s ease;
        z-index: -1;
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:has(.rail-track:hover) {
        .bgimage {
          transform: scale(1.1);
        }
      }
    }

    .rail-track {
      display: flex;
      white-space: nowrap;
      will-change: transform;
      gap: 24px;
      width: fit-content;
      transition: transform 0.5s ease-out;
      position: relative;
      z-index: 1000000000;

      h2 {
        font-size: 180px;
        color: white;
        font-weight: 800;
        text-align: center;

        @media (min-width: 350px) and (max-width: 640px) {
          font-size: 60px;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          font-size: 100px;
        }

        @media (min-width: 1280px) and (max-width: 1536px) {
          font-size: 130px;
        }
      }

      &:hover {
        h2 {
          color: transparent;
          -webkit-text-stroke: 1px white;
        }
      }
    }

    .rail-track:hover~.bgimage {
      transform: scale(1.1);
    }

    @keyframes scroll-left {
      0% {
        transform: translateX(100%);
      }

      100% {
        transform: translateX(-100%);
      }
    }
  }

  .meta-awakening-scroll-container {
    scroll-snap-type: y mandatory;
    height: 100vh;
    overflow-y: auto;
    scroll-behavior: smooth;

  }

  .other-events-card-bg-img {
    height: 700px !important;
    width: 100%;
    object-fit: cover;
  }

  .Awakening-section {
    scroll-snap-align: start;
    height: 100vh;
    width: 100%;
    position: sticky;
    transition: all 0.3s ease;
    top: 60px;
    overflow: hidden;

    @media (min-width:350px) and (max-width:640px) {
      height: 600px;
    }
  }

  .event-progress-container {
    width: 100%;
    position: absolute;
    padding: 60px 60px;
    padding-top: 0px;
    inset: 0% 0 20vh;
    z-index: 10;
    pointer-events: none;
    height: 100%;

    @media (min-width:350px) and (max-width:640px) {
      bottom: 10vh;
      padding: 20px 20px;
      padding-top: 0px;
    }

    .event-progress-sticky {
      position: sticky;
      top: 830px;
      height: 10px;
      z-index: 100;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 0px 100px;

      @media (min-width:350px) and (max-width:1536px) {
        padding: 0px;
        top: 650px;
      }

      .event-progress-div {
        background-color: #fff3;
        width: 100%;
        height: 4px;
        overflow: hidden;

        .event-progress-bar {
          background-color: white;
          height: 4px;
          display: block;
        }
      }
    }
  }
}

// Home Page - Events Section Responsive 
.exce-home-event-section {

  @media (min-width:350px) and (max-width:640px) {
    position: relative;
    padding-bottom: 6rem !important;
    padding-top: 1rem !important;
  }

  .swiper-wrapper {
    @media (min-width:1280px) and (max-width:1536px) {
      display: flex !important;
      gap: 7rem !important;
    }
  }

  .title-heading {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 40px !important;
    }

  }

  .swiper-content {
    @media (min-width:768px) and (max-width:1024px) {
      padding: 0 2rem !important;
    }
  }

  .bh-event-image {

    @media (min-width:350px) and (max-width:1024px) {
      height: 200px !important;
    }

    @media (min-width:768px) and (max-width:1536px) {
      height: 300px !important;
    }


    img {
      @media (min-width:350px) {
        height: 100%;
        object-fit: cover;
      }
    }

  }

  .exce-home-event-title {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (min-width:350px) and (max-width:640px) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .event-badge {
      @media (min-width:350px) and (max-width:640px) {
        width: fit-content;
        padding: 0.5rem 1rem;
      }
    }

  }

  .exce-home-event-price {
    @media (min-width:350px) and (max-width:640px) {
      display: flex;
      flex-direction: column;
    }

    span {
      display: flex;
    }
  }

  .exce-home-event-price {
    @media (min-width:350px) and (max-width:640px) {
      display: flex;
      flex-direction: column;
    }

    span {
      display: flex;
    }
  }

  .exce-home-event-footer {
    height: 60px;

    @media (min-width:350px) and (max-width:640px) {
      display: flex;
      align-items: center;
      gap: 1rem;
      height: 108px;
    }

  }

  .exce-home-event-btn-toolbar {

    display: flex;
    gap: 1rem;

    @media (min-width:350px) and (max-width:640px) {
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: center;
      gap: 1rem;
    }

    button {
      font-weight: 600;
      padding: 0.5rem 2rem;
      background-color: #fff;
      border: none;
    }

    button:hover {
      background-color: transparent;
      color: white;
      outline: 1px solid white
    }
  }

  .exce-home-event-navigation {

    @media (min-width:350px) and (max-width:640px) {
      position: absolute;
      top: 35rem !important;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

  }

}

// Home Page - Initiatives Section Responsive
.exce-home-page-our-initiatives {

  .title-heading {
    @media (min-width:350px) and (max-width:640px) {
      font-size: 40px;
    }

    @media (min-width:768px) {
      font-size: 60px;
    }
  }

  .exce-initiative-row-main {
    @media (min-width:768px) and (max-width:1024px) {
      flex-direction: column;
      gap: 2rem;
    }

    .exce-initiative-col-main,
    .exce-initiative-col-another {
      @media (min-width:768px) and (max-width:1024px) {
        width: 100%;
      }
    }
  }


  .initiatives-view-tab {
    @media (min-width:350px) and (max-width:1024px) {
      flex-direction: column;
      gap: 2rem;
    }

    .initiatives-view-tab-item {
      @media (min-width:350px) and (max-width:1024px) {
        width: 100%;
      }
    }
  }
}

.exce-video-img-card {
  @media (max-width:640px) {
    width: 100%;
  }
}